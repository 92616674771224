<template>
    <main>
        <h1>sounds for six days</h1>
    </main>
</template>
<style scoped>
h1 {
    text-transform: uppercase;
    font-family: helvetica, san-serif;
    font-weight: 300;
    font-size: calc(1.2rem + 0.7vw);
    text-align: center;
}
</style>
      